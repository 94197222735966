import React from 'react';
import Gallery from './Gallery';
import '../styles/HomePage.css';
import MusicPlayer from './MusicPlayer';
import libertyLogo from '../styles/libertyLogo.png'; 

const HomePage = () => {
  const galleryItems1 = [
    { type: 'image', src: '../images/drIam.gif', alt: 'Image 1' },
    { type: 'image', src: '../images/margebutton.jpg', alt: 'Image 6' },
    { type: 'image', src: '../images/libertyBoosters.gif' },
    { type: 'image', src: '../images/gih05.jpg', alt: 'Image 2' },
    { type: 'image', src: '../images/pharmurka.jpg', alt: 'Image 3' },
    { type: 'image', src: '../images/boosted.jpg', alt: 'Image 4' },
    { type: 'image', src: '../images/sooperTardling.gif' },

    { type: 'image', src: '../images/green.gif', alt: 'Image 5' },
    { type: 'image', src: '../images/battle.gif' },
    { type: 'image', src: '../images/ivermectinboard.png', alt: 'Image 11' },
  
    { type: 'image', src: '../images/adrenochrome.gif', alt: 'Image 7' },
    { type: 'image', src: '../images/clotshotboard.png', alt: 'Image 8' },
    { type: 'image', src: '../images/protesters.gif' },
   

  
  ];

  const galleryItems2 = [
    { type: 'image', src: '../images/tread.jpg', alt: 'Image 1' },
    { type: 'image', src: '../images/machines.gif' },
    { type: 'image', src: '../images/globocorp.jpg', alt: 'Image 2' },
    { type: 'image', src: '../images/pepe.gif', alt: 'Image 4' },
    { type: 'image', src: '../images/georgebutton.jpg', alt: 'Image 5' },
    { type: 'image', src: '../images/dungeon.gif' },
   
    { type: 'image', src: '../images/gbi.png', alt: 'Image 11' },
    { type: 'image', src: '../images/clownMap-bg.png' },
    
    { type: 'image', src: '../images/drabroto.gif', alt: 'Image 3' },
    { type: 'image', src: '../images/appealtoheavan.jpg', alt: 'Image 11' },
    { type: 'image', src: '../images/2a.png', alt: 'Image 11' },
    { type: 'image', src: '../images/technobutoon.jpg', alt: 'Image 11' },

    { type: 'image', src: '../images/rhatz.gif', alt: 'Image 11' },
    { type: 'image', src: '../images/chad.gif', alt: 'Image 5' },

  ];
    const galleryItems3 = [
      { type: 'image', src: '../images/youngjoe.png', alt: 'young joe' },
      { type: 'image', src: '../images/starcucks.gif' },
      { type: 'image', src: '../images/globocorp.png', alt: 'Image 2' },
      { type: 'image', src: '../images/rpg.gif', alt: 'Image 4' },
      { type: 'image', src: '../images/queentardling.png', alt: 'Image 5' },
      { type: 'image', src: '../images/msnpc.gif', alt: 'Image 6' },
      { type: 'image', src: '../images/targheys.jpg' },
      { type: 'image', src: '../images/justine.jpg', alt: 'Image 11' },
      { type: 'image', src: '../images/tardlingprotestors.png', alt: 'Image 11' },
  
    
  ];

  return (
    <div className="homepage">
      <img src={libertyLogo} alt="Liberty Logo" className="homepage-logo" />

      <section className="gallery-section">
        <h1 className="gallery-title">STATUS LIBERTATIS INFANTIS: NUNTII EVOLUTIONIS</h1>
        <h1 className="gallery-title">Julius, Anno Domini MMXXIV</h1>
        <Gallery items={galleryItems3} />
        </section>
        <section className="gallery-section">
        <h1 className="gallery-title">Junius, Anno Domini MMXXIV</h1>
        <MusicPlayer />
        </section>

      <section className="gallery-section">
        <h1 className="gallery-title">Maius, Anno Domini MMXXIV</h1>
        <Gallery items={galleryItems1} />
      </section>

      <section className="gallery-section">
        <h1 className="gallery-title">Aprilis, Anno Domini MMXXIV</h1>
        <Gallery items={galleryItems2} />
      </section>
      {/* Add more sections as needed */}
    </div>
  );
};

export default HomePage;

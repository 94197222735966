import React from 'react';
import { Link, Route, Routes, Navigate } from 'react-router-dom';
import '../styles/InfoPage.css';
import SectionTemplate from './SectionTemplate';

const globocorpItems = [
  { image: '/images/plannedUgenX.png', alt: 'Planned UgenX', text: 'Planned UgenX' },
  { image: '/images/globotarduniversity.png', alt: 'Globotard University', text: 'Globotard University' },
  { image: '/images/gato.png', alt: 'G.A.T.O', text: 'G.A.T.O.' },
  { image: '/images/starcucks.png', alt: 'Starcucks', text: 'Starcucks' },
  { image: '/images/msnpc.png', alt: 'MSNPC', text: 'MSNPC' },
  { image: '/images/globobo.png', alt: 'globobo', text: 'globobo' },
  { image: '/images/gih.png', alt: 'G.I.H.', text: 'G.I.H.' },
  { image: '/images/targheys.png', alt: 'Targhey', text: 'Targhey' },
  { image: '/images/carboloads.png', alt: 'Carboloads', text: 'Carboloads' },
  { image: '/images/feq.png', alt: 'THE FEG', text: 'THE FEG' },
  { image: '/images/pharmurka.png', alt: 'Pharmurka', text: 'Pharmurka' },
  { image: '/images/gogriftme.png', alt: 'gogriftme', text: 'gogriftme' },
];

const mostlyPeacevilleItems = [
  { image: '/images/zone002.png', alt: 'Zone 002', text: 'Zone 2: Targheys Superstore' },
  { image: '/images/zone001.png', alt: 'Zone 001', text: 'Zone 1: MSNPC' },
  { image: '/images/zone000.png', alt: 'Zone 000', text: 'Zone 0: G.I.H.' },
  { image: '/images/placeholder.png', alt: 'Zone 005', text: 'Zone 5: globobo' },
  { image: '/images/placeholder.png', alt: 'Zone 004', text: 'Zone 4: Autonomous Zone' },
  { image: '/images/placeholder.png', alt: 'Zone 003', text: 'Zone 3: Globotard University' },
  { image: '/images/placeholder.png', alt: 'Zone 008', text: 'Zone 8: THE FEQ' },
  { image: '/images/placeholder.png', alt: 'Zone 007', text: 'Zone 7: G.A.T.O.' },
  { image: '/images/placeholder.png', alt: 'Zone 006', text: 'Zone 6: GloboCorp' },
];

const bestiaryItems = [
  { image: '/images/glowies.png', alt: 'Glowies', text: 'Glowies' },
  { image: '/images/armedglowie.png', alt: 'Armed Glowies', text: 'Armed Glowies' },
  { image: '/images/pinkpussierhatz.png', alt: 'Pink Pussy Rhatz', text: 'Pink Pussy Rhatz' },
  { image: '/images/clottot.png', alt: 'Clot Tots', text: 'Clot Tots' },
  { image: '/images/tardlings.png', alt: 'Tardlings', text: 'Tardlings' },
  { image: '/images/tardlingprotestors.png', alt: 'Tardling Protester', text: 'Tardling Protester' },
  { image: '/images/queentardling.png', alt: 'Tardling Queens', text: 'Tardling Queens' },
  { image: '/images/gwenneth.png', alt: 'Wrenneth', text: 'Wrenneth' },
  { image: '/images/PlandemicDancers.png', alt: 'Plandemic Dancers', text: 'Plandemic Dancers' },
  { image: '/images/draborto.png', alt: 'Dr. Aborto', text: 'Dr. Aborto' },
  { image: '/images/DrIam.png', alt: 'DrIam', text: 'DrIam' },
  { image: '/images/reeee.png', alt: 'reeeeee', text: 'reeeeee' },
  { image: '/images/dwanlimwan.png', alt: 'Dwanleimwan', text: 'Dwanleimwan' },
  { image: '/images/storytime.png', alt: 'Miss Storytime', text: 'Storytime!' },
  { image: '/images/thelizardqueen.png', alt: 'The Lizard Queen', text: 'The Lizard Queen' },
  { image: '/images/screenz.png', alt: 'Screenz', text: 'Screenz' },
  { image: '/images/mediaorganic.png', alt: 'Media Organic', text: 'Media Organic' },
  { image: '/images/groomer.png', alt: 'Groomzilda', text: 'Groomzilda' },
  { image: '/images/stan.png', alt: 'Stan', text: 'Stan' },
  { image: '/images/m.png', alt: 'M', text: 'M' },
  { image: '/images/shoogabrainz.png', alt: 'shoogabrainz', text: 'shoogabrainz' },
  { image: '/images/teeth.png', alt: 'Teeth', text: 'Teeth' },
  { image: '/images/thelibrarain.png', alt: 'the Librarian', text: 'The Librarian' },
  { image: '/images/Wunch.png', alt: 'Wunch', text: 'Wunch' },
  { image: '/images/eternalsadness.png', alt: 'Eternal Sadness', text: 'Eternal Sadness' },
  { image: '/images/margenx.png', alt: 'MargenX', text: 'MargenX' },
  { image: '/images/demdonkey.png', alt: 'Dem Donkey', text: 'Dem Donkey' },
 
  { image: '/images/placeholder.png', alt: 'placeholder', text: 'I' },
  { image: '/images/placeholder.png', alt: 'placeholder', text: 'KEEP' },
  { image: '/images/placeholder.png', alt: 'placeholder', text: 'ADDING' },

];

const sonsOfLibertyItems = [
  { image: '/images/lilG.png', alt: 'lil G', text: 'lil G' },
  { image: '/images/YoungJoe.png', alt: 'Young Joe', text: 'Young Joe' },
  { image: '/images/theLion.png', alt: 'The Lion', text: 'The Lion' },
  { image: '/images/olebloodnguts.png', alt: 'Ole Blood n Guts', text: 'Ole Blood n Guts' },
  { image: '/images/lilLadyLiberty.png', alt: 'lil Lady Liberty', text: 'lil Lady Liberty' },
  { image: '/images/Mikey.png', alt: 'Mikey', text: 'Mikey' },
  { image: '/images/tubman.png', alt: 'Tubman', text: 'Minty' },
  { image: '/images/mexichad.png', alt: 'MexiChad', text: 'Mexi-Chad' },
  { image: '/images/blackchad.png', alt: 'Black Chad', text: 'Black Chad' },
  { image: '/images/caucasionchad.png', alt: 'Caucasion Chad', text: 'Caucasion Chad' },
  { image: '/images/rooftopkorean.png', alt: 'Rooftop Korean', text: 'Rooftop Korean' },
  { image: '/images/deigoventey.png', alt: 'Diego Ven-Tey', text: 'Diego Ven-Tey' },
  // { image: '/images/methodman.png', alt: 'Method Man', text: 'Method Man' },
  // { image: '/images/infotree.png', alt: 'Infotree', text: 'Infotree' },
  // { image: '/images/jickfofenwick.png', alt: 'Jikfo Fenwick', text: 'Jikfo Fenwick' },
];

const InfoPage = () => {
  return (
    <div className="info-page">
      <nav className="info-nav">
        <Link to="/info/globocorp" className="info-nav-link">GloboCorp</Link>
        <Link to="/info/mostlypeaceville" className="info-nav-link">Mostly Peaceville</Link>
        <Link to="/info/bestiary" className="info-nav-link">Bestiary</Link>
        <Link to="/info/sonsofliberty" className="info-nav-link">Sons of Liberty</Link>
      </nav>
      <div className="info-content">
        <Routes>
          <Route path="/" element={<Navigate to="/info/globocorp" />} />
          <Route path="/globocorp" element={<GloboCorp />} />
          <Route path="/mostlypeaceville" element={<MostlyPeaceville />} />
          <Route path="/bestiary" element={<Bestiary />} />
          <Route path="/sonsofliberty" element={<SonsOfLiberty />} />
        </Routes>
      </div>
    </div>
  );
};

const GloboCorp = () => (
  <div className="info-section">
    <img src="/images/globoCorp.png" alt="Featured GloboCorp" className="featured-image" />
    <SectionTemplate items={globocorpItems} />
  </div>
);

const MostlyPeaceville = () => (
  <div className="info-section">
    <img src="/images/mostlyPeaceville.png" alt="Featured Mostly Peaceville" className="featured-image" />
    <SectionTemplate items={mostlyPeacevilleItems} />
  </div>
);

const Bestiary = () => (
  <div className="info-section">
    <img src="/images/bestiary1.png" alt="Featured Bestiary" className="featured-image" />
    <SectionTemplate items={bestiaryItems} />
  </div>
);

const SonsOfLiberty = () => (
  <div className="info-section">
    <img src="/images/sons.png" alt="Featured Sons of Liberty" className="featured-image" />
    <SectionTemplate items={sonsOfLibertyItems} />
  </div>
);

export default InfoPage;

import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/Header.css';
import logo from '../styles/mostlypeacefulgames.png';
import { FaStar } from "react-icons/fa";

const Header = () => {
  return (
    <header className="header">
      <img src={logo} alt="Logo" className="logo" />
      <nav className="nav">
        <Link to="/" className="nav-link">Liberty Baby</Link>
        <Link to="/info" className="nav-link">Clown World</Link>
        <Link to="/about" className="nav-link">About</Link>
      </nav>
      <div className="stars-background">
        {[...Array(13)].map((_, i) => (
          <FaStar 
            key={i} 
            className="star" 
            style={{ ...getStarPosition(i), opacity: 0.25 }} 
          />
        ))}
      </div>
    </header>
  );
};

const getStarPosition = (index) => {
  const leftPercentage = 3 + (index * (94 / 12)); // Start at 3% and end at 97%
  return {
    top: '30%',
    left: `${leftPercentage}%`
  };
};

export default Header;

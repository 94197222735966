import React from 'react';
import { Routes, Route } from 'react-router-dom';
import HomePage from './components/HomePage';
import About from './components/About';
import Header from './components/Header';
import InfoPage from './components/InfoPage';

const App = () => {
  return (
    <div>
      <Header />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<About />} />
        <Route path="/info/*" element={<InfoPage />} />
      </Routes>
    </div>
  );
};

export default App;

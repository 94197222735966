import React from 'react';
import '../styles/About.css';
// import libertyLogo from '../styles/libertyLogo.png'; // Adjust the path as necessary

const About = () => {
  return (
    <div className="about-container">
      {/* <img src={libertyLogo} alt="Liberty Logo" className="about-logo" /> */}
      <h1 className="about-title">Liberty Baby Saves the Globe</h1>
      <p>
  A 2D adventure game under solo-development by{' '}
  <a href="https://www.basedpotato.com" target="_blank" rel="noopener noreferrer">
    D.R. Albright
  </a> 
  {' '}under the studio name Mostly Peaceful Games.
</p>

      <p className="about-text">A pint-sized hero on a mission to save Mostly Peaceville from the evil clutches of GloboCorp and its army of mindless Tardling Minions. Armed with an unwavering resolve and the pesky Bill of Rights, Liberty Baby must navigate a world gone mad, 
      restoring truth, justice, and humanity one musketball at a time.</p>
      <p className="about-text">
     
      </p>

      <h1 className="about-title">Support</h1>
      <p className="about-text">
        ETH Address: <span className="crypto-address">0xCd34365A2415fc6505B2947d68b17B922E216D21</span>
      </p>
      <p className="about-text">
        BTC Address: <span className="crypto-address">bc1qhjzjjf3dyqhyrhy3rtq77dpwut0jm200qyrsny</span>
      </p>
      <p className="about-text">
        DOGE Address: <span className="crypto-address">D7KomWToNpXuywRewYVUkFAQPVRZ8XFj12</span>
      </p>

      <h1 className="about-title">Contact</h1>
      <p className="about-text">
        Follow{' '}
        <a href="https://twitter.com/peacefulgames" target="_blank" rel="noopener noreferrer">
          @peacefulgames
        </a>{' '}
        on X
      </p>
      <p className="about-text">
        Rumble channel:{' '}
        <a href="https://rumble.com/c/c-1817031" target="_blank" rel="noopener noreferrer">
          https://rumble.com/c/c-1817031
        </a>
      </p>

<h1 className="about-title">Disclaimer</h1>
      <p className="about-text">
      This is a video game. A cartoon. It's fake. It's not real. "Liberty Baby Save the Globe" is satire and/or parody played out in a fictional universe. The characters in this game are not actual people, and the events are purely the product of a crazy, retarded imagination and lots of weed. This game is a game, which means its for entertainment purposes only and not intended to inspire real-world behavior. The game creator disavows all real-life dipshits who do stupid, violent shit in the real-world. But also, stay strapped. You are responsbile for you. 
      </p>
    </div>
  );
};

export default About;

import React from 'react';
import '../styles/SectionTemplate.css';

const SectionTemplate = ({ items, isSprite }) => {
  return (
    <div className="section-template">
      <div className="grid-container">
        {items.map((item, i) => (
          <div key={i} className="grid-item">
            {isSprite ? (
              <div className="sprite" style={{ backgroundImage: `url(${item.sprite})` }} />
            ) : (
              <img src={item.image} alt={item.alt} className="grid-image" />
            )}
            <p className="grid-text">{item.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SectionTemplate;

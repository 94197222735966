import React, { useState, useRef, useEffect } from 'react';
import ReactAudioPlayer from 'react-audio-player';
import '../styles/MusicPlayer.css';

const MusicPlayer = () => {
  const [songs, setSongs] = useState([]);
  const [currentSongIndex, setCurrentSongIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null);
  // const lyricsRef = useRef(null);

  useEffect(() => {
    fetch('/lyrics.json')
      .then((response) => response.json())
      .then((data) => setSongs(data))
      .catch((error) => console.error('Error fetching the lyrics.json file:', error));
  }, []);

  const playNextSong = () => {
    setCurrentSongIndex((currentSongIndex + 1) % songs.length);
    setIsPlaying(true);
  };

  const playPreviousSong = () => {
    setCurrentSongIndex((currentSongIndex - 1 + songs.length) % songs.length);
    setIsPlaying(true);
  };

  useEffect(() => {
    if (audioRef.current && songs.length > 0 && isPlaying) {
      audioRef.current.audioEl.current.play();
      // if (lyricsRef.current) {
      //   const scrollTime = songs[currentSongIndex].scrollTime || 30; // Default to 30s if not specified
      //   lyricsRef.current.style.animation = 'none';
      //   setTimeout(() => {
      //     lyricsRef.current.style.animation = `scrollLyrics ${scrollTime}s linear forwards`;
      //   }, 0);
      // }
    }
  }, [currentSongIndex, songs, isPlaying]);

  const handlePlayPause = (isPlaying) => {
    setIsPlaying(isPlaying);
  };

  const currentSong = songs[currentSongIndex];

  return (
    <div className="music-player">
      <div className="title-container">
        <h1 className="title">LIBERTY BABY SINGS THE SONGS OF FREEDOM VOL. 1: NO BRAKES</h1>
      </div>
      <div className="content">
        <div className="sidebar">
          <div className="song-list">
            {songs.map((song, index) => (
              <div
                key={index}
                onClick={() => setCurrentSongIndex(index)}
                className={`song-item ${currentSongIndex === index ? 'active' : ''}`}
              >
                <p>{song.title}</p>
              </div>
            ))}
          </div>
        </div>
        {currentSong && (
          <div className="player-container">
            <div className="image-container">
              <img src={currentSong.img} alt={currentSong.alt} />
              {/* <div className="lyrics-container">
                <div className="lyrics" ref={lyricsRef}>
                  {currentSong.lyrics.split('\n').map((line, index) => (
                    <p key={index}>{line}</p>
                  ))}
                </div>
              </div> */}
            </div>
            <ReactAudioPlayer
              src={currentSong.src}
              controls
              ref={audioRef}
              onPlay={() => handlePlayPause(true)}
              onPause={() => handlePlayPause(false)}
              onEnded={playNextSong}
            />
            <div className="current-song-info">
              <img src={currentSong.img} alt={currentSong.alt} className="thumbnail" />
              <p>{currentSong.title}</p>
            </div>
            <div className="controls">
              <button className="arrow-button" onClick={playPreviousSong}>&larr;</button>
              <button className="arrow-button" onClick={playNextSong}>&rarr;</button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MusicPlayer;

import React from 'react';
import Masonry from 'react-masonry-css';
import '../styles/Gallery.css';

const breakpointColumnsObj = {
  default: 3, // Changed from 4 to 3
  1100: 3,
  700: 2,
  500: 1
};

const Gallery = ({ items }) => {
  return (
    <Masonry
      breakpointCols={breakpointColumnsObj}
      className="my-masonry-grid"
      columnClassName="my-masonry-grid_column"
    >
      {items.map((item, index) => (
        <div key={index} className="gallery-item">
          {item.type === 'image' ? (
            <img src={item.src} alt={item.alt || `Gallery item ${index + 1}`} />
          ) : (
            <video controls>
              <source src={item.src} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          )}
        </div>
      ))}
    </Masonry>
  );
};

export default Gallery;
